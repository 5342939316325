import { Button, Form, Input } from "antd";
import { AiOutlineUser } from "react-icons/ai";
import { TbHomeMove } from "react-icons/tb";
import { RiLockPasswordLine } from "react-icons/ri";
import Captcha from "../component/Captcha";
import { useEffect, useState } from "react";
import { AuthenticationService } from "../Services/AuthService";
import Swal from "sweetalert2";
import Loader from "../global/Loader";
import { Link, useNavigate } from "react-router-dom";
import cgcost from "../assets/images/cgcost logo.png";
import { set } from "../global/Encryption";
import Cookies from "js-cookie";

const authService = new AuthenticationService();

function LoginPage() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [captchaObj, setCaptchaObj] = useState();
  const [loadingCaptcha, setLoadingCaptcha] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);

  useEffect(() => {
    getUpdateCaptcha();

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('err') === "afs") {
        Swal.fire({
            html: 'Session has expired or invalid. Please log in again.',
            icon: 'info',
            confirmButtonText: 'OK',
        }).then(() => {
            // Remove 'err' query parameter
            const url = new URL(window.location);
            queryParams.delete('err');
            url.search = queryParams.toString();
            window.history.replaceState({}, '', url);
        });
    }
    else if(queryParams.get('err') === "sto"){
      Swal.fire({
        html: 'Session Timeout !',
        icon: 'info',
        confirmButtonText: 'OK',
    }).then(() => {
        // Remove 'err' query parameter
        const url = new URL(window.location);
        queryParams.delete('err');
        url.search = queryParams.toString();
        window.history.replaceState({}, '', url);
    });
    }
}, []);


 

  
  const getUpdateCaptcha = async () => {
    try {
      setLoadingCaptcha(true);
      const captchaObjData = await authService.getCaptcha();
      setCaptchaObj(captchaObjData.data);
    } catch (error) {
      Swal.fire({
        html: error,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    } finally {
      setLoadingCaptcha(false);
    }
  };

  const loginSubmit = async () => {
    try {
      setLoadingLogin(true);

      if (Cookies.get("authToken")) {
        const userData = authService.getUserData;
        if (userData?.role === "3" || userData?.role === "2") {
          Swal.fire({
            html: "You are already logged in.",
            icon: "success",
          });
          navigate("/user/home");
        }
      }
       else {
        const payload = {
          emailId: form.getFieldValue("emailId"),
          password: set(form.getFieldValue("password")).toString(),
          captchaId: captchaObj?.captchaID,
          userEnteredCaptcha: form.getFieldValue("captcha"),
        };

        const res = await authService.userLogin(payload);
        if (res.data.isLoginSuccessful) {
          if (res.data.oldSessionId === "") {
            Cookies.set("authToken", res.data.authToken);
            Cookies.set("refreshToken", res.data.refreshToken);
            Cookies.set("loginTimeStamp", new Date().getTime().toString());
            localStorage.setItem("loginTime",new Date().getTime().toString());

            const userData = authService.getUserData;
            if (userData?.role === "3" || userData?.role === "2") {
              navigate("/user/home");
            }
          } else {
            Swal.fire({
              html: "Your previous session is still active. Would you like to terminate the previous session?",
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, logout!",
            }).then(async (result) => {
              if (result.isConfirmed) {
                const payload = {
                  userId: res.data.userId,
                  newSessionId: res.data.newSessionId,
                };

                const res2 = await authService.terminatePreviousSession(payload);
                if (res2.status) {
                  Cookies.set("authToken", res.data.authToken);
                  Cookies.set("refreshToken", res.data.refreshToken);
                  Cookies.set("loginTimeStamp", new Date().getTime().toString());
                  localStorage.setItem("loginTime",new Date().getTime().toString());
                  const userData = authService.getUserData;
                  Swal.fire({
                    html: "Your previous session has been terminated.",
                    icon: "success",
                  });
                  if (userData?.role === "3" || userData?.role === "2") {
                    navigate("/user/home");
                  }
                }
              }
            });
            getUpdateCaptcha();
          }
        } else {
          Swal.fire({
            html: res.data.message,
            icon: "warning",
          });
          getUpdateCaptcha();
        }
      }
    } catch (error) {
      Swal.fire({
        html: error.message,
        icon: "error",
      });
    } finally {
      setLoadingLogin(false);
    }
  };

  return (
    <>
      {loadingLogin && <Loader />}
      <div className="login">
        <div className="box">
          <div className="form">
            <div className="text-center logo">
              <img src={cgcost} alt="cgcost" className="img-fluid" />
            </div>
            <Form
              layout="vertical"
              form={form}
              name="loginForm"
              onFinish={loginSubmit}
              scrollToFirstError
              autoComplete="off"
            >
              <div className="input-box">
                <Form.Item
                  name="emailId"
                  label="User Id / Email Id"
                  rules={[{ required: true, message: "Required!" }]}
                >
                  <Input
                    prefix={<AiOutlineUser />}
                    placeholder="Enter Email Id / User Id"
                    className="rounded-pill"
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true, message: "Required!" }]}
                >
                  <Input.Password
                    prefix={<RiLockPasswordLine />}
                    placeholder="Enter Your Password"
                    className="rounded-pill"
                    autoComplete="off"
                  />
                </Form.Item>
                <div className="captcha-box">
                  <Captcha
                    imgData={captchaObj?.captchaData || ""}
                    refresh={getUpdateCaptcha}
                    minLength={6}
                    maxLength={6}
                    isLoading={loadingCaptcha}
                    rules={[{ required: true, message: "Please enter the captcha shown in the image" }]}
                  />
                </div>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="btn-login">
                    Login
                  </Button>
                </Form.Item>
              </div>
            </Form>
            <Link to={"/"} className="btn btn-primary btn-home">
              Back To Home <TbHomeMove />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;


