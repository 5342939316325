import { Suspense } from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import SubHeader from "../component/SubHeader";
import Footer from "../component/Footer";
import Header from "../component/Header";
import Loader from "../global/Loader";
import ScrollToTop from '../component/ScrollToTop'
// import Test from "../component/Test";

function Layout() {
  return (
    <>
          <ScrollToTop/>
      <SubHeader />
      <Header />
      {/* <Test/> */}
      <Container fluid className="box-layout" id="main-content">
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Container>
      <Footer />
    </>
  );
}

export default Layout;
