import { Col, Container, Row } from "react-bootstrap";
import BreadcrumbLayout from "../component/Breadcrumb";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();
  const ContactData = t("Contact", { returnObjects: true });

  return (
    <>
      <BreadcrumbLayout title="Contact" />
      <Container fluid >
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{ContactData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1">
                <Row>
                  <Col xs={12}>
                    <h3>
                      {ContactData.heading}
                    </h3>
                  </Col>
                  <Col xs={5} sm={3} md={2}>
                    <b>{ContactData.address.title}</b>
                  </Col>
                  <Col xs={7} sm={9} md={10}>
                    <span>{ContactData.address.data}</span>
                  </Col>

                  <Col xs={5} sm={3} md={2}>
                    <b>{ContactData.contactNo.title}</b>
                  </Col>
                  <Col xs={7} sm={9} md={10}>
                    <span>{ContactData.contactNo.data}</span>
                  </Col>

                  <Col xs={5} sm={3} md={2}>
                    <b>{ContactData.email.title}</b>
                  </Col>
                  <Col xs={7} sm={9} md={10}>
                    <span
                      dangerouslySetInnerHTML={{ __html: ContactData.email.data }}
                    ></span>
                  </Col>
                  <Col xs={12} className="mt-3">
                    <Card>
                      <Card.Body>
                        <iframe
                          title="Google Maps"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3717.8820047012496!2d81.6809564!3d21.2761378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13fe028c89624f9d%3A0x6352cbc6b659e914!2sChhattisgarh%20Council%20Of%20Science%20And%20Technology%20CGCOST!5e0!3m2!1sen!2sin!4v1705448760972!5m2!1sen!2sin"
                          width="100%"
                          height="450"
                          style={{ border: 0 }}
                          allowFullScreen
                          loading="lazy"
                        ></iframe>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>



              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;
