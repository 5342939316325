import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { I18nextProvider } from "react-i18next";
// import i18next from "i18next";
// import English from "./Translation/en.json";
// import Hindi from "./Translation/hi.json";
// import './i18n/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

// i18next.init({
//   interpolation: { escapeValue: false },
//   lng: "hi",
//   resources: {
//     en: {
//       translation: English, 
//     },
//     hi: {
//       translation: Hindi, 
//     },
//   },
// });
root.render(
  <React.StrictMode>
     <App />
    {/* <I18nextProvider i18n={i18next}>
     
    </I18nextProvider> */}
  </React.StrictMode>
);
reportWebVitals();
