import React from 'react'
import Gallery from '../../component/video-gallery/Gallery'
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
export default function VideoGallery() {
  const { t } = useTranslation();
  return (
    <>
      
      <BreadcrumbLayout title="Video Gallery" />
<Container className='outer-border'>
      <Row>
        <Col xs={12} className='inner-border'>
           <h3>{t("breadcrumb.video-gallery")}</h3>
        </Col>
        <Col xs={12} className='mb-1 mt-1'>
        <Gallery/>
        
       </Col>
        
        
      </Row>
    </Container>
    </>
  )
}
