import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { Form, Select, Image, Pagination, Empty } from "antd";
import Loader from "../../global/Loader";
import { ImageGalleryService } from "../../Services/ImageGalleryService";
import demoUser from "../../assets/images/broken-img.jpg";
import PhotoGallerySidebar from "./PhotoGallerySidebar";


export default function ImageGallery() {

  const { t, i18n } = useTranslation();
  const menuData = t("menu.media-gallery", { returnObjects: true, }) || {};
  const imageGalleryService = useMemo(() => new ImageGalleryService(), []);
  const [filterListData, setFilterListData] = useState([]);
  const [filterType, setFilterType] = useState({ filterType: "-1" });
  const [form] = Form.useForm();
  const [loadingDDL, setLoadingDDL] = useState(true);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [tblData, setTblData] = useState([]);
  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);


  useEffect(() => {
    const getImageById = async (imgDocUniqueId, imgDocMtype) => {
      try {
        if (imgDocUniqueId !== null) {
          const res = await imageGalleryService.ViewDocument(imgDocUniqueId);
          if (res.status=== 200) {
            const fileBlob = new Blob([res.data], { type: imgDocMtype });
            const dataUrl = URL.createObjectURL(fileBlob);
            return dataUrl;
          }
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }

      return demoUser;
    };

    const getPosterGallery = async (galleryTypeId, language, imageCategoryType) => {
      try {
        setLoadingTblData(true);
        const res = await imageGalleryService.GetImageListPublic(galleryTypeId, language, imageCategoryType);
        const imgUrlPromises = res.data.table.map(item =>
          getImageById(item.docUniqueId, item.docMimeType)
        );
        const imgUrls = await Promise.all(imgUrlPromises);

        const data = res.data.table.map((item, index) => ({
          key: index + 1,
          imageId: item.imageId,
          description: item.description,
          imageCategoryType: item.imageCategoryType,
          imagenotes: item.imagenotes,
          doc: {
            docMimeType: item.docMimeType,
            docUniqueId: item.docUniqueId,
          },
          ImgUrl: imgUrls[index],
        }));

        setTblData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingTblData(false);
      }
    };

    getPosterGallery(1, lang, form.getFieldValue("filterType"));
  }, [imageGalleryService, filterType, form, lang]);




  const loadTableFilter = (value) => {
    setFilterType({ filterType: value })
  };

  useEffect(() => {
    const getContentTypes = async (type) => {
      try {
        setLoadingDDL(true);
        const res = await imageGalleryService.getCommonList(type, lang);
        // setFilterListData(res.data)   
        setFilterListData(() => {
          let data = [
            {
              value: '-1',
              name: (lang === 1 ? "-- सभी --":"-- Any --"),
              label: (lang === 1 ? "-- सभी --":"-- Any --"),
              nameHindi: '',
              type: '',
              extraField1: ''
            }
          ];
          for (let i = 0; i < res.data.length; i++) {
            data.push(
              {
                value: res.data[i].value,
                name: res.data[i].name,
                label: res.data[i].label,
                nameHindi: res.data[i].nameHindi,
                type: res.data[i].type,
                extraField1: res.data[i].extraField1
              }
            )
          }
          return data;
        })

      }
      catch (error) {
        setLoadingDDL(false);
      }
      finally {
        setLoadingDDL(false);
      }
    }
    getContentTypes("imageCategoryType");

  }, [imageGalleryService, lang]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9); // Set as per your requirement

  // Calculate the index of the first and last item to be displayed based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentImages = tblData.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle pagination change
  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setItemsPerPage(pageSize);
  };



  return (
    <>
      {loadingTblData && <Loader />}
      <BreadcrumbLayout title="Map Gallery" />
      <Container fluid>
        <Row>
         {/* Sidebar */}
         <PhotoGallerySidebar menuData={menuData} />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("breadcrumb.photo-gallery")}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <Row>
                  <Col lg={12} className='mb-2'>
                    <div className='img-category'>
                      <Form form={form} initialValues={filterType}>
                        <Form.Item name="filterType" label={lang === 1 ? "छवि श्रेणी चुनें:":"Select Image Category:"}
                        labelCol={{ style: { fontWeight: 'bold' } }}>
                          <Select
                            showSearch
                            placeholder="Select Category"
                            loading={loadingDDL}
                            optionFilterProp="children"
                            onChange={loadTableFilter}
                            options={filterListData}
                            filterOption={(input, option) =>
                              (option?.label ?? "").toLowerCase().includes(
                                input.toLowerCase()
                              )
                            }
                          />
                        </Form.Item>
                      </Form>
                    </div>
                  </Col>

                </Row>

                {currentImages.length > 0 ?
                  <>
                    <Row className='galleryWrap'>
                      <Image.PreviewGroup
                      >

                        {
                          currentImages && currentImages.map((slide, index) => {
                            return (
                              <Col lg={4}

                                key={index}
                              >
                                <Image
                                  src={slide.ImgUrl}
                                  alt={"Image-" + slide.index}
                                />
                                <p className="image-description">{slide.description}</p>
                              </Col>
                            )
                          })
                        }
                      </Image.PreviewGroup>
                    </Row>
                    <Row justify="center">
                      <Col>
                        <div className='img-pagination' style={{ paddingBottom: "20px" }}>
                          <Pagination
                            current={currentPage}
                            pageSize={itemsPerPage}
                            total={tblData.length}
                            onChange={handlePaginationChange}
                            showSizeChanger={true}
                            pageSizeOptions={['9', '18', '36', '72']}
                          />
                        </div>
                      </Col>
                    </Row>
                  </> :
                  <>
                    <Row>
                      <Col>
                        <>
                          < Empty description={lang === 1 ? <span style={{ fontWeight: 'bold' }}>वर्तमान में, प्रदर्शित करने के लिए कोई डेटा नहीं है ।</span> : <span style={{ fontWeight: 'bold' }}>Currently, There are no data to display.</span>} />
                        </>
                      </Col>
                    </Row>
                  </>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}


