
import React, { useEffect, useState, useMemo } from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import PDFGallery from "../../component/PDFGallery";
import { useTranslation } from "react-i18next";
import Loader from "../../global/Loader";
import { PosterGalleryService } from "../../Services/PosterGalleryService";

import demoUser from "../../assets/images/broken-img.jpg";
import PhotoGallerySidebar from "./PhotoGallerySidebar";

export default function PosterGallery() {
  const { t } = useTranslation();
  const menuData =
    t("menu.media-gallery", {
      returnObjects: true,
    }) || {};
  const posterGalleryService = useMemo(() => new PosterGalleryService(), []);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [tblData, setTblData] = useState([]);
  

  useEffect(() => {
    const getImageById = async (imgDocUniqueId, imgDocMtype) => {
      try {
        if (imgDocUniqueId !== null) {
          const res = await posterGalleryService.ViewDocument(imgDocUniqueId);
          if (res.status=== 200) {
            const fileBlob = new Blob([res.data], { type: imgDocMtype });
            const dataUrl = URL.createObjectURL(fileBlob);
            return dataUrl;
          }
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }

      return demoUser;
    };

    const getPosterGallery = async () => {
      try {
        setLoadingTblData(true);
        const res = await posterGalleryService.GetPosterListPublic();
        const imgUrlPromises = res.data.table.map(item =>
          getImageById(item.imgUniqueId, item.imgMimeType)
        );
        const imgUrls = await Promise.all(imgUrlPromises);

        const data = res.data.table.map((item, index) => ({
          key: index + 1,
          posterId: item.posterId,
          posternotes: item.posternotes,
          doc: {
            docMimeType: item.docMimeType,
            docUniqueId: item.docUniqueId,
          },
          Img: {
            imgMimeType: item.imgMimeType,
            imgUniqueId: item.imgUniqueId,
          },
          ImgUrl: imgUrls[index],
        }));

        setTblData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingTblData(false);
      }
    };

    getPosterGallery();
  }, [posterGalleryService]);

  return (
    <>
      {loadingTblData && <Loader />}
      <BreadcrumbLayout title="Poster Gallery" />
      <Container fluid>
        <Row>
          {/* Sidebar */}
          <PhotoGallerySidebar menuData={menuData} />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("breadcrumb.poster-gallery")}</h3>
              </Col>
              <Col xs={12} className="mb-3 mt-3">
                <PDFGallery galleryItems={tblData}/>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
