import React, { useEffect, useState, useMemo } from "react";
import { Table, Button,Empty } from "antd";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
// import fileIcon from "../../assets/images/File-icon.png";
import { useTranslation } from "react-i18next";

import Loader from "../../global/Loader";
import { PresentationService } from "../../Services/PresentationService";
import Swal from "sweetalert2";
import PhotoGallerySidebar from "./PhotoGallerySidebar";

export default function PresentationGallery() {
  const { t, i18n } = useTranslation();
  // const Data = t("PresentationGallery", { returnObjects: true, } || {});
  const menuData = t("menu.media-gallery", { returnObjects: true, }) || {};
  const presentationService = useMemo(() => new PresentationService(), []);
  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);

  const viewDoc = async (doc) => {
    try {
      setPageLoading(true);
      if (doc.DocUniqueId !== null) {
        const res = await presentationService.ViewDocument(doc.DocUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: doc.DocMimeType });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The document hasn't been uploaded yet. Please try again later.",
        });
      }
    }
    catch (error) {
      console.error("Error fetching documents:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unexpected error occurred. Please try again.",
      });
    }
    finally{
      setPageLoading(false);
    }


  };

 
  const columns = [
    {
      title: "#",
      render: (record) => {
        return (
          <div className="text-center">{record.key + "."}</div>
        );
      },
      fixed: "left",
      width: "5%",
    },
    {
      title: lang === 1 ? "प्रेजेंटेशन शीर्षक (प्रेजेंटेशन दिनांक)" : "Presentation Title (Presentation Date)",
      render: (row) => {
        return (
          <>
            <div className="project-details">
              <h5><span className="fw-bold">{row.title} </span> </h5>
              <p><span className="fw-bold">{lang === 1 ? "प्रेजेंटेशन दिनांक:" : "Date of Presentation:"}   </span>{row.presentationDate}</p>
            </div>
          </>
        )
      },
      fixed: 'left',
      width: '50%'
    },
    {
      title: lang === 1 ? "विवरण देखें" : "View Details",
      render: (row) => {
        return (
          <>
            <div className="col text-center">
              <Button
                onClick={() => viewDoc(row.doc)}
                className="btn btn-sm btn-outline-primary mt-2 "
              >
                {lang === 1 ? "डाउनलोड" : "Download"}

              </Button>
            </div>

          </>
        )
      },
      fixed: 'left',
      width: '10%'
    },
  ];

  useEffect(() => {
    const getPresentationList = async (language) => {
      try {
        setLoadingTblData(true);

        const res = await presentationService.GetPresentationListPublic(language);
        setTblData(() => {
          let data = res.data.table.map((item, index) => ({
            key: index + 1,
            contentId: item.contentId,
            title: item.title,
            presentationDate: item.presentationDate,
            doc: {
              DocUniqueId: item.uniqueId,
              DocMimeType: item.documentMimeType,

            }
          }));
          return data;
        });
      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };
    getPresentationList(lang);
  }, [lang, presentationService]);
  return (
    <>
      {loadingTblData && <Loader />}
      {pageLoading && <Loader /> }
      <BreadcrumbLayout title="" />
      <Container fluid>
        <Row>
        <PhotoGallerySidebar menuData={menuData} />
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("PresentationGallery.title")}</h3>
              </Col>
              <Col xs={12} className="mb-3 mt-3">
                <div className="table-responsive-md tableList">
                  <Table
                    bordered
                    columns={columns}
                    dataSource={tblData}
                    loading={loadingTblData}
                    // scroll={{ x: 600 }}
                    size="small"
                    locale={{
                      emptyText: (
                          <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={lang === 1 ? <span style={{ fontWeight: 'bold' }}>वर्तमान में, प्रदर्शित करने के लिए कोई डेटा नहीं है ।</span> : <span style={{ fontWeight: 'bold' }}>Currently, There are no data to display.</span>} 
                          />
                      )
                  }}
                  pagination={{
                    pageSize,
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => setPageSize(size),
                    pageSizeOptions: ["5", "10", "20", "50", "100"],
                  }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
