import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { AuthenticationService } from "../Services/AuthService";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_baseUrl,
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const authToken = Cookies.get("authToken");
        const loginTime = Cookies.get("loginTimeStamp");

        if (authToken && loginTime) {
            const loginTimeStamp = new Date(+loginTime).getTime();
            const currentTime = new Date().getTime();
            if ((currentTime - loginTimeStamp) <= (50 * 60 * 1000)) {
                config.headers.Authorization = `Bearer ${authToken}`;
            } else if ((currentTime - loginTimeStamp) > (50 * 60 * 1000) && (currentTime - loginTimeStamp) <= (60 * 60 * 1000)) {
                await refreshAuthToken();
                config.headers.Authorization = `Bearer ${authToken}`;
            } else {
                handleSessionTimeout();
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const refreshAuthToken = async () => {
    const authService = new AuthenticationService();
    let payload = {
        authToken: Cookies.get("authToken"),
        refreshToken: Cookies.get("refreshToken"),
    };
    try {
        const res = await authService.refreshAuthToken(payload);
        Cookies.set('authToken', res.data.authToken);
        Cookies.set('refreshToken', res.data.refreshToken);
        sessionStorage.setItem('loginTimeStamp', new Date().getTime().toString());
    } catch (error) {
        handleTokenRefreshError(error);
    }
};

const handleSessionTimeout = () => {
    Swal.fire({
        html: 'Session Timeout!',
        icon: 'info',
        showConfirmButton: false,
        timer: 2000,
    });
    const authService = new AuthenticationService();
    authService.logout();
};

const handleTokenRefreshError = (error) => {
    console.error('Failed to refresh auth token:', error);
    Swal.fire({
        title: 'Unauthorized!',
        html: error.message,
        icon: 'error',
    });
    const authService = new AuthenticationService();
    authService.logout();
};

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            // Swal.fire({
            //     title: 'Session has expired or invalid. Please log in again!',
            //     html: error.message,
            //     icon: 'error',
            //     confirmButtonText: 'OK'
            // });
            Cookies.remove("authToken");
            Cookies.remove("refreshToken");
            Cookies.remove("loginTimeStamp");
            sessionStorage.clear();
            // window.location.href = '/login';
            window.location.href = `/login?err=afs`;
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;


