import React from 'react'
import { Col, Row, Container, Table } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import AboutSidebar from "./AboutSideBar";

export default function GeneralBody() {
  const { t } = useTranslation();
  const menuData =
    t("menu", {
      returnObjects: true,
    }) || {};
  return (
    <>
      <BreadcrumbLayout title="GeneralBody" />
      <Container fluid >
        <Row>
          {/* Sidebar */}
      <AboutSidebar menuData={menuData} />
      {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("generalbody.title")}</h3>
              </Col>
              <Col xs={12} className='mb-1 mt-1'>
                <p style={{textAlign:"justify"}}>{t("generalbody.paragraph")}
                </p>
                <div className='ml-2 mr-2' >
                  <Table striped bordered hover className="officers-list mt-3">
                    <thead>
                      <tr>
                        <th>{t('generalbody.sn')}</th>
                        <th>{t('generalbody.department')}</th>
                        <th>{t('generalbody.designation')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {t('generalbody.data', { returnObjects: true }).map(item => (
                        <tr key={item.srn}>
                          <td>{item.srn}</td>
                          <td>{item.Department}</td>
                          <td>{item.Designation}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
