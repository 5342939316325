import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import { FaLink } from "react-icons/fa6";
import Swal from "sweetalert2";
export default function QuickLinks() {
  const { t, i18n } = useTranslation();
  const QuickLinksData = t("QuickLinks", { returnObjects: true });
  const links = [
    { href: "http://www.ccostgis.cg.nic.in/", text: "www.ccostgis.cg.nic.in" },
    { href: "http://www.dst.gov.in/", text: "www.dst.gov.in" },
    { href: "http://www.iirs.gov.in/", text: "www.iirs.gov.in" },
    { href: "http://www.nrsc.gov.in/", text: "www.nrsc.gov.in" },
    { href: "http://www.cgstate.gov.in/", text: "www.cgstate.gov.in" }
  ];
  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);

  const handleClick = (url) => {
    Swal.fire({
      html: lang === 2 ? `
                This link shall take you to a page/ website outside this website. `
        : ` यह लिंक आपको इस वेबसाइट के बाहर एक पेज/वेबसाइट पर ले जाएगा।`,
      icon: "warning",
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: lang === 2 ?"OK":"",
      denyButtonText: lang === 2 ?"Cancel":""
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(url);
      }
    });
  };
  return (
    <>
      <BreadcrumbLayout title="Quick Links" />
      <Container fluid >
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{QuickLinksData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-3">

                {/* <ul className="nav-list ms-3">
                  {links.map((link, index) => (
                    <li key={index}>
                      <a
                        href={link.href}
                        target="_blank"
                        rel="noopener noreferrer">
                        {link.text}
                      </a>
                    </li>
                  ))}
                </ul> */}

                <div className="downloadable-list2">

                  <ul className=" ms-4">
                    {links.map((list, i) => (
                      <li key={i}>
                        <div className="d-table-cell">
                          <FaLink /></div>
                        <div className="d-table-cell">
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault(); // Prevents the default link behavior
                              handleClick(list.href);
                            }}

                          >
                            {list.text}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
