import React from "react";
import { Col, Row, Container, Table } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Help() {
  const { t } = useTranslation();
  const HelpData = t("Help", { returnObjects: true });

  return (
    <>
      <BreadcrumbLayout title="Help" />
      <Container>
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{HelpData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {HelpData.data.map((dt, i) => (
                  <div key={i}>
                    <Table striped bordered hover className="officers-list mt-3">
                      <thead>
                        <tr>
                          <th colSpan={2}>{HelpData.view}</th>
                        </tr>
                        <tr>
                          <th>{HelpData.type}</th>
                          <th>{HelpData.download}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dt.tableData.map((d) => (
                          <tr key={d.id}>
                            <td>{d.documentType}</td>
                            <td>
                              <a href={d.fileName} rel="noreferrer" target="_blank" title="Click to get Download page" className="external">{d.fileTitle}</a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="mt-3">
                      {dt.paragraph.map((d, j) => (
                        <div key={j} className="heading-paragraph mb-5">
                          <h3>{d.title}</h3>
                          <p dangerouslySetInnerHTML={{ __html: d.content }}></p>
                          <div className="ms-5">
                            {d.list.map((p, k) => (
                              <p key={k} dangerouslySetInnerHTML={{ __html: p }}></p>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="heading-paragraph" key={i}>
                      {dt.navlist.map((nv, j) => (
                        <div key={j}>
                          <h3>{nv.title}</h3>
                          <ul className="ms-5">
                            {nv.list.map((n, k) => (
                              <li key={k}>
                                <NavLink to={n.to}>
                                  {n.title}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Help;
