import React from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";
const AboutSidebar = ({ menuData }) => {
  return (
    <Col
      md={3}
      className="sidebar order-last order-md-first bg-light p-3"
      style={{
        borderRight: '1px solid #dee2e6',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Row>
        <Col className="menu-list">
          <ul className="list-unstyled">
            {[
              { path: "/about/introduction", label: menuData.about.introduction },
              { path: "/about/mission-objectives", label: menuData.about.missionObjectives },
              { path: "/about/from-director-general", label: menuData.about.fromDirectorGeneral },
              { path: "/about/general-body", label: menuData.about.generalBody },
              { path: "/about/executive-committee", label: menuData.about.executiveCommittee },
              { path: "/about/annual-report", label: menuData.about.annualReport },
              { path: "/about/whos-who", label: menuData.about.WhosWho.title },
              { path: "/about/organizational-chart", label: menuData.about.organizationalChart },
            ].map((item, index) => (
              <NavLink
                key={index}
                to={item.path}
                className="d-block py-2 px-3 mb-1"
                style={{
                  color: '#495057',
                  borderRadius: '4px',
                  textDecoration: 'none',
                  transition: 'background-color 0.2s',
                }}
                activeStyle={{ backgroundColor: '#007bff', color: 'white' }}
              >
                <li>{item.label}</li>
              </NavLink>
            ))}
          </ul>
        </Col>
      </Row>
    </Col>
  );
};

export default AboutSidebar;
