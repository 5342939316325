import React from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const NewsEventSidebar = ({ menuData }) => {
  const { i18n } = useTranslation();
  return (
    <Col
      md={3}
      className="sidebar order-last order-md-first bg-light p-3"
      style={{
        borderRight: '1px solid #dee2e6',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Row>
        <Col className="menu-list">
          <ul className="list-unstyled">
            {[
               
             
              
           
              { path: "/notice-board/news-events", label: menuData["news-events"] },
              { path: "/notice-board/archive-news", label:  i18n.language === "en" ?"Archive News" :"पुरालेख समाचार"},
              { path: "/notice-board/tenders", label: menuData.tenders },
              { path: "/notice-board/archive-tender", label: i18n.language === "en" ?"Archive Tenders" :"पुरालेख निविदाएं" },
              { path: "/notice-board/advertisement", label: menuData.advertisement },
              { path: "/notice-board/archive-advertisement", label: i18n.language === "en" ?"Archive Advertisement" :"पुरालेख विज्ञापन" }

              
              



            ].map((item, index) => (
              <NavLink
                key={index}
                to={item.path}
                className="d-block py-2 px-3 mb-1"
                style={{
                  color: '#495057',
                  borderRadius: '4px',
                  textDecoration: 'none',
                  transition: 'background-color 0.2s',
                }}
                activeStyle={{ backgroundColor: '#007bff', color: 'white' }}
              >
                <li>{item.label}</li>
              </NavLink>
            ))}
          </ul>
        </Col>
      </Row>
    </Col>
  );
};

export default NewsEventSidebar;
