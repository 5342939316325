import { Col, Container, Form, Nav, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { Tooltip } from "antd";
import SwitchTextSize from "./SwitchTextSize";

const locales = [
  { id: "hi", title: "हिंदी" },
  { id: "en", title: "English" },
];

function SubHeader() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  return (
    <>
      <Container fluid className="sub-header">
        <Row className="clearfix b-size">
          <Col md={7} lg={5} >
            <Row className="common-left">
            <div className="col-lg-5 col-md-4 col-6 text-end mt-2">
              <span className="d-block">छत्तीसगढ़ शासन</span>
              <span className="d-block">GOVERNMENT OF CHHATTISGARH</span>
              </div>
              <div className="col-lg-7 col-md-6 col-6 mt-2 border-left">
              <span className="d-block">विज्ञान और प्रौद्योगिकी विभाग</span>
              <span className="d-block">DEPARTMENT OF SCIENCE AND TECHNOLOGY </span>
              </div>
              <div className="col-12 border-top mt-2">
               
              </div>
            </Row>
          </Col>
          <Col lg={3} className="d-none d-lg-block"></Col>
          
          <Col md={5} lg={4} className="d-flex justify-content-center">
      <div className="common-right clearfix">
        <Nav as="ul" className="font-acc  mt-1">
          <Nav.Item as="li">
            <Tooltip placement="bottom" title="Skip to main content">
              <Nav.Link href="#main-content" className="">
                <span>
                  <FaRegArrowAltCircleDown />
                </span>
                <span className="d-none d-lg-inline-block">
                  {t("subHeader.mainContent")}
                </span>
              </Nav.Link>
            </Tooltip>
          </Nav.Item>

          <SwitchTextSize />

          <Nav.Item as="li">
            <Form.Select
              style={{ fontWeight: "bold" }}
              onChange={(ev) => changeLanguage(ev.target.value)}
              value={localStorage.getItem('language') || 'hi'}
            >
              {locales.map((locale) => (
                <option key={locale.id} value={locale.id}>
                  {locale.title}
                </option>
              ))}
            </Form.Select>
          </Nav.Item>
        </Nav>
      </div>
    </Col>
        </Row>
      </Container>
    </>
  );
}

export default SubHeader;
