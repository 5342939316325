import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProjectService } from "../Services/ProjectService";
import Loader from "../global/Loader";
import Swal from "sweetalert2";
import { Table, Button, Empty } from "antd";

export default function ProjectTable({ projectAreas, projectType }) {
  const { i18n } = useTranslation();
  const projectService = useMemo(() => new ProjectService(), []);
  const [tblData, setTblData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setLang(i18n.language === "en" ? 2 : 1);
  }, [i18n.language]);

  useEffect(() => {
    const fetchProjectList = async () => {
      setLoading(true);
      try {
        const res = await projectService.GetProjectListPublic(projectAreas, projectType, lang);
        const data = res.data.table.map((item, index) => ({
          key: index + 1,
          title: item.title,
          tenure: item.tenure,
          investigator: item.investigator,
          docEn: { DocUniqueId: item.enDocUniqueId, DocMimeType: item.enDocMimeType },
          docHi: { DocMimeType: item.hiDocMimeType, DocUniqueId: item.hiDocUniqueId },
        }));
        setTblData(data);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong while fetching the project list!",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchProjectList();
  }, [lang, projectAreas, projectType, projectService]);

  const viewDoc = async (doc) => {
    setLoading(true);
    try {
      if (doc.DocUniqueId) {
        const res = await projectService.ViewDocument(doc.DocUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: doc.DocMimeType });
          const dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The document hasn't been uploaded yet. Please try again later.",
        });
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong while fetching the document!",
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "#",
      render: (record) => <div className="text-center">{record.key}.</div>,
      width: "5%",
    },
    {
      title: lang === 2 ? "Project Title (Principal Investigator)" : "परियोजना का शीर्षक (प्रधान अन्वेषक)",
      render: (row) => (
        <div className="project-details mt-3">
          <h5><span className="fw-bold">{row.title}</span></h5>
          <p><span className="fw-bold">{lang === 2 ? 'Principal Investigator:' : 'प्रधान अन्वेषक:'}</span> {row.investigator}</p>
          <p><span className="fw-bold">{lang === 2 ? 'Tenure of the Project:' : 'परियोजना की अवधि:'}</span> {row.tenure}</p>
        </div>
      ),
    },
    {
      title: lang === 2 ? "Detailed Description" : "विस्तृत विवरण",
      render: (row) => (
        <div className="col text-center">
          <Button
            onClick={() => viewDoc(lang === 2 ? row.docEn : row.docHi)}
            className="btn btn-sm btn-outline-primary mt-2"
          >
            {lang === 2 ? "View Details" : "विवरण देखें"}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="table-responsive-md tableList">
      {loading && <Loader />}
      <Table
        bordered
        columns={columns}
        dataSource={tblData}
        loading={loading}
        scroll={{ x: 600 }}
        size="small"
        pagination={{
          pageSize,
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setPageSize(size),
          pageSizeOptions: ["5", "10", "20", "50", "100"],
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={lang === 1 ? <span style={{ fontWeight: 'bold' }}>वर्तमान में, प्रदर्शित करने के लिए कोई डेटा नहीं है ।</span> : <span style={{ fontWeight: 'bold' }}>Currently, There are no data to display.</span>}
            />
          )
        }}
      />
    </div>
  );
}
