import React, { useEffect, useState, useMemo } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Table, Button, Image, Empty } from "antd";
import { useTranslation } from "react-i18next";
import BreadcrumbLayout from "../../component/Breadcrumb";
import Loader from "../../global/Loader";
import { OfficerListService } from "../../Services/OfficerListService";
import Swal from "sweetalert2";
import demoUser from "../../assets/images/demo-user.jpg";
import AboutSidebar from "./AboutSideBar";

export default function ListOfOfficers() {
  const { t, i18n } = useTranslation();
  const menuData =
  t("menu", {
    returnObjects: true,
  }) || {};
  const officerListService = useMemo(() => new OfficerListService(), []);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [tblData, setTblData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [pageSize, setPageSize] = useState(15); // Page size state
  const [lang, setLang] = useState(localStorage.getItem("language") === "en" ? 2 : 1);

  useEffect(() => {
    setLang(localStorage.getItem("language") === "en" ? 2 : 1);
  }, [i18n.language]);

  useEffect(() => {
    const getImageById = async (imgDocUniqueId, imgDocType) => {
      try {
        if (imgDocUniqueId !== null) {
          const res = await officerListService.ViewDocument(imgDocUniqueId);
          if (res.status=== 200) {
            const fileBlob = new Blob([res.data], { type: imgDocType });
            const dataUrl = URL.createObjectURL(fileBlob);
            return dataUrl;
          }
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
      return demoUser;
    };

    const getOfficersList = async (lang) => {
      try {
        setLoadingTblData(true);
        const res = await officerListService.GetOfficerlistPublic(lang);
        const imgUrlPromises = res.data.table.map((item) => getImageById(item.imgDocUniqueId));
        const imgUrls = await Promise.all(imgUrlPromises);
        setTblData(
          res.data.table.map((item, index) => ({
            key: index + 1,
            officerId: item.officerId,
            name: item.name,
            post: item.post,
            designation: item.designation,
            rsmDocUniqueId: item.rsmDocUniqueId,
            imgDocUniqueId: item.imgDocUniqueId,
            ImgUrl: imgUrls[index]
          }))
        );
      } catch (error) {
        console.error("Error fetching officer list:", error);
      } finally {
        setLoadingTblData(false);
      }
    };

    getOfficersList(lang);
  }, [lang, officerListService]);

  const viewDoc = async (rsmDocUniqueId) => {
    try {
      setPageLoading(true);
      if (rsmDocUniqueId !== null) {
        const res = await officerListService.ViewDocument(rsmDocUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: "application/pdf" });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: lang===2?"Oops...":"क्षमा करें...",
          text: lang===2?"The document hasn't been uploaded yet. Please try again later.!":"दस्तावेज़ अभी तक अपलोड नहीं किया गया है. कृपया बाद में पुन: प्रयास करें।!",
        });
      }
    } catch (error) {
      console.error("Error fetching resume:", error);
      Swal.fire({
        icon: "error",
        title: lang===2?"Oops...":"क्षमा करें",
        text: lang===2?"We encountered a problem while fetching the document. Please try again later.":"दस्तावेज़ प्राप्त करते समय कोई समस्या हुई। कृपया बाद में पुनः प्रयास करें।",
      });
    } finally {
      setPageLoading(false);
    }
  };

  const columns = [
    {
      title: "#",
      render: (record) => {
        return <div className="text-center">{record.key + "."}</div>;
      }
    },
    {
      title: lang === 2 ? "Image" : "Image",
      render: (row) => {
        return (
          <div>
            <Image
              src={row.ImgUrl}
              alt="Imagesdghsd"
              className="img-fluid"
              style={{
                width: "115px",
                height: "140",
                border: "0.5px solid darkgrey",
                // padding: "5px",
                borderRadius: "50%",
              }}
            />
          </div>
        );
      }
     
    },
    {
      title: lang === 2 ? "Details" : "विवरण",
      render: (row) => {
        return (
          <>
            <p>
              <span className="fw-bold">{row.name} </span>{" "}
            </p>
            <p>{row.post}</p>
            <p>{row.designation}</p>
          </>
        );
      }
    },
    {
      title: lang === 2 ? "Resume" : "Resume",
      render: (row) => {
        return (
          <div className="col text-center">
            <Button onClick={() => viewDoc(row.rsmDocUniqueId)} className="btn btn-sm btn-outline-primary mt-2">
              {lang === 2 ? "View" : "देखे"}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {/* {loadingTblData && <Loader />} */}
      {pageLoading && <Loader />}
      <BreadcrumbLayout title="List Of Officers" />
      <Container fluid>
        <Row>
          {/* Sidebar */}
      <AboutSidebar menuData={menuData} />
      {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("ListOfOfficers.title")}</h3>
              </Col>
              <Col xs={12} className="mb-3 mt-3">
                <div className="table-responsive-md tableList">
                  <Table
                    bordered
                    columns={columns}
                    dataSource={tblData}
                    loading={loadingTblData}
                    scroll={{ x: 600 }}
                    size="small"
                    pagination={{
                      pageSize,
                      showSizeChanger: true,
                      onShowSizeChange: (current, size) => setPageSize(size),
                      pageSizeOptions: ["5", "10", "15","20", "50", "100"],
                    }}
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            lang === 1 ? (
                              <span style={{ fontWeight: "bold" }}>वर्तमान में, प्रदर्शित करने के लिए कोई डेटा नहीं है ।</span>
                            ) : (
                              <span style={{ fontWeight: "bold" }}>Currently, There are no data to display.</span>
                            )
                          }
                        />
                      ),
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
